import '@/styles/global.css';

import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { appWithTranslation } from 'next-i18next';
import SuperTokensReact, { SuperTokensWrapper } from 'supertokens-auth-react';

import { frontendConfig } from '@/config/frontendConfig';

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
  display: 'swap',
});

if (typeof window !== 'undefined') {
  SuperTokensReact.init(frontendConfig());
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <SuperTokensWrapper>
      <main className={inter.className}>
        <Component {...pageProps} />
      </main>
    </SuperTokensWrapper>
  );
};

export default appWithTranslation(MyApp);
