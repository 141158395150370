import type { SuperTokensConfig } from 'supertokens-auth-react/lib/build/types';
import EmailPasswordReact from 'supertokens-auth-react/recipe/emailpassword';
import EmailVerificationReact from 'supertokens-auth-react/recipe/emailverification';
import SessionReact from 'supertokens-auth-react/recipe/session';

import { appInfo } from './appInfo';

export const frontendConfig = (): SuperTokensConfig => {
  return {
    appInfo,
    recipeList: [
      EmailVerificationReact.init({ mode: 'OPTIONAL' }),
      EmailPasswordReact.init(),
      SessionReact.init({
        override: {
          functions: (oI) => ({
            ...oI,
            getGlobalClaimValidators: ({
              claimValidatorsAddedByOtherRecipes,
            }) => [
              ...claimValidatorsAddedByOtherRecipes.filter(
                (v) => v.id !== 'st-ev'
              ),
              EmailVerificationReact.EmailVerificationClaim.validators.isVerified(
                300,
                100000
              ),
            ],
          }),
        },
      }),
    ],
  };
};
