export const NEXT_PUBLIC_BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || 'http://192.168.1.5:3000';

export const appInfo = {
  appName: 'WinLand',
  websiteDomain: NEXT_PUBLIC_BASE_URL,
  apiDomain: NEXT_PUBLIC_BASE_URL,
  apiBasePath: '/api/auth',
  websiteBasePath: '/auth',
};
